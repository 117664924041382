table {
  table-layout: fixed;
}

tbody {
  display: block;
  height: 300px !important;
  overflow-y: auto !important;
  cursor: default;
}

td {
  width: 2000px;
}

#keyColumn {
  min-width: 80px;
  max-width: 100px;
}

#artistColumn {
  width: 2000px;
}

#songColumn {
  width: 2000px;
}

/* Colors */

#keyColumn .ui.table td.active, .ui.table tr.active {
  background: #A13D63 !important;
}
#keyColumn a:hover {
  background: pink;
  color: black;
}

#artistColumn .ui.table td.active, .ui.table tr.active {
  background: #555599 !important;
}
#artistColumn a:hover {
  background: lightblue;
  color: black;
}

#songColumn .ui.table td.active, .ui.table tr.active {
  background: rgba(101, 179, 101, 0.466) !important;
}
#songColumn a:hover {
  background: lightgreen;
  color: black;
}
