body {
  /* background-color: #333333; */
  color: rgba(244, 247, 190, 0.466);
}

.title {
  color: #F0A249;
}

h1 {
  padding-top: 20px;
}

h2 {
  line-height: 10px;
}

.spotify {
  position: fixed;
  top: 370px; /* 20px; */
  right: 20px;
}

.partLabel {
  color: #A13D63;
}

p {
  /* font-family: "Courier New"; */
  font-family: "Monaco";
  /* font-family: 'Inconsolata', monospace; */
  color: #84D2F6;
  white-space: pre;
}

p.chord {
  color: #7D6B91;
}

p.lyric {
  color: #989FCE;
}

.toolbar {
  background: #222222;
  display: flex;
  padding: 1em;
}

.toolbar > div {
  margin: 0 1em;
}
