.flex-container-key-capo {
    border: 0.1em solid #777777;
    background: #222222;
    margin-top: 2em;
    width: 40em;
    padding: 1em;
    display: flex;
    justify-content: space-evenly;
    /* align-items: baseline; */
}

.flex-container-key-capo > div {
    width: 8em;
    /* height: 5em; */
    /* text-align: center; */
    /* background: green; */
    display: flex;
    flex-direction: column;
    /* margin: 0em 1em; */
    /* justify-content: space-between; */
    align-items: center;
}

.label-key-capo {
    color: #AAAAAA;
    font-size: small;
}

.immutable-key {
    font-weight: 900;
    font-size: x-large;
}
